export type ModalKeys = 'case.ledger.payment.update'
| 'case.ledger.charge.update'
| 'case.notes.edit'
| 'case.scra.import'
| 'case.docketWizard'
| 'case.evictionWizard'
| 'case.courtReschedule'
| 'case.challengeTransition'
| 'case.challengeHold'
| 'case.changeTrialDate'
| 'case.changeEvictionDate'
| 'lease.case.create'
| 'lease.ledger.charge.create'
| 'lease.ledger.payment.create'
| 'lease.documents.create'
| 'confirm.generic'
| 'tooltips.create'
| 'tooltips.edit'
| 'integration.action.execute'
| 'organization.chartOfAccounts.import'
| 'users.role.crud'
| 'case.printQueue.export'
| 'case.adjudicationWizard'
| 'allowedCategories.create'

export interface Modal<T = Record<string, any>> {
  isOpen: boolean
  data: T
  transition: boolean
}

export interface Modals extends Record<string, Modal> {
}

const modals = ref<Modals>({})

function createModalInstance(key: ModalKeys) {
  if (!modals.value[key]) {
    modals.value[key] = reactive({
      isOpen: false,
      data: {},
      transition: false,
    })
  }
}

export function useModal<T>(key: ModalKeys) {
  createModalInstance(key)

  const openModal = (data?: Modal['data']) => {
    modals.value[key].transition = true
    modals.value[key].isOpen = true

    if (data)
      modals.value[key].data = data
  }

  const closeModal = () => {
    watchOnce(() => modals.value[key].transition, () => {
      modals.value[key].data = {}
    })

    modals.value[key].isOpen = false
  }

  return {
    modal: ref(modals.value[key]) as Ref<Modal<T>>,
    openModal,
    closeModal,
  }
}

export function isModalOpen(key: ModalKeys) {
  const { modal } = useModal(key)

  return modal.value.isOpen
}

export function shouldRenderModal(key: ModalKeys) {
  const { modal } = useModal(key)

  if (modal.value.transition)
    return true

  return modal.value.isOpen
}
